<template>
  <div>
    <div class="search">
      <div>
        <div class="a-inline-block">
          <label for="">关键字</label>
          <a-input v-model="key" size="large" placeholder="项目名、客户名、联系人、联系方式" />
        </div>
        <div class="a-inline-block">
          <label for="">状态</label>
          <a-select v-model="status" class="ant-select-lg" style="background:#fff">
            <a-select-option :value="index" v-for="(item,index) in statusList" :key="index">
              {{item}}
            </a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <label for="">跟进人</label>
          <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="showStaff(1)" placeholder="请选择跟进人" />
          <!-- <a-select v-model="searchStaff"  class="ant-select-lg" style="background:#fff">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">
                            {{item.name}}
                        </a-select-option>
                    </a-select> -->
        </div>
        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Action" slot-scope="text,record">
        <a class="action" href="javascript:;" @click="edit(record.id)">编辑</a>
        <a class="action" href="javascript:;" @click="showStaff(2,record.id)">线索分配</a>
        <a class="action" href="javascript:;" @click="showClient(record)">转商机</a>

        <!-- <a-popconfirm title="确定要转商机吗?" @confirm="() => toBus(record.id)">
          <a class="action" href="javascript:;">转商机</a>
        </a-popconfirm> -->
        <a class="action del" href="javascript:;" @click="showGiveUp(record.id)">放弃</a>
      </template>
    </a-table>
    <a-modal v-model="visible" :width="400" title="线索分配" @ok="clueAllot">
      <div class="addBox">
        <div class="add_title">请选择跟进人</div>
        <a-select v-model="onUid" class="add_inp" style="width: 250px">
          <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
    <a-modal v-model="ShowGive" title="放弃线索" @ok="submitGiveUp">
      <div class="addBox">
        <div class="add_title">请输入放弃原因及备注</div>
        <a-textarea v-model="giveUpText" />
      </div>
    </a-modal>
    <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
    <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
  </div>
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import Client from "../../../components/client.vue";

import { requestXml, getDateTime } from "../../../assets/js/request";
export default {
  components: {
    Staff,
    Client,
  },
  data() {
    return {
      key: "",
      type: null,
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        { title: "线索名称", dataIndex: "name", key: "1" },
        { title: "客户名称", dataIndex: "customerid", key: "2" },
        { title: "联系人", dataIndex: "customeruser", key: "3" },
        { title: "联系方式", dataIndex: "customertel", key: "4" },
        { title: "状态", dataIndex: "status_text", key: "5" },
        { title: "跟进人", dataIndex: "sname", key: "6" },
        { title: "时间", dataIndex: "created", key: "7" },
        {
          title: "操作",
          key: "operation",
          align: "center",
          fixed: "right",
          width: 350,
          scopedSlots: { customRender: "Action" },
        },
      ],
      statusList: ["未处理", "已跟进", "转商机", "已放弃"],
      status: "",
      data: [],
      staff: [], //员工列表
      staffFlag: false,
      clientFlag: false,
      clientMode:0,
      corpid: "",
      onStaff: {
        name: "",
        userid: "",
      },
      onType: "",
      searchStaff: "", //搜索用选中跟进人
      visible: false, //线索分配弹框
      onCid: "", //选中的线索id
      onUid: "", //选中的员工id
      ShowGive: false, //放弃弹框
      giveUpText: "", //放弃原由
      onCdata: "", //选中的线索信息
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
    };
  },
  mounted: function () {
    // this.getStaff();
    this.clientMode=1;
    this.getData();
  },
  methods: {
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getData(this.key, this.status);
    },
    // 重置搜索条件
    reset() {
      this.key = "";
      this.type = null;
      this.status = null;
      this.searchStaff = "";
      this.onStaff.userid = "";
      this.onStaff.name = "";
      this.getData();
    },
    showStaff(type, id) {
      this.onType = type;
      id ? (this.onCid = id) : "";
      this.staffFlag = true;
    },
    // 负责人-输入框聚焦是清除其焦点
    focus(e) {
      e.srcElement.blur();
    },
    // 分配完
    updateStaffFlag(data) {
      // console.log(data,"dasds")
      if (data != 1) {
        if (this.onType == 1) {
          this.onStaff = data;
        } else if (this.onType == 2) {
          this.onUid = data.userid;
          this.clueAllot();
        }
      }
      this.staffFlag = false;
    },
    // 选择客户
    updateClientFlag(data) {
      // this.client = [];
      // this.client2 = [];
      if (data && data != 1) {
        this.corpid = data[0].tmjcpb_corp__ids;
        this.addChance();
      }

      //   console.log(this.corpid);
      // for(let i=0;i<data.length;i++){
      //     this.client.push(data[i].tmjcpb_corp__ids);
      //     this.client2.push(data[i].tmjcpb_corp__name);
      //     // let obj = {"tmjcpb_corp__ids":data[i].tmjcpb_corp__ids,"tmjcpb_corp__name":data[i].tmjcpb_corp__name};
      //     // this.client.push(obj);
      //     // console.log(this.client)
      //     // this.client2.push(data[i].tmjcpb_corp__name);
      // }
      this.clientFlag = false;
    },
    //打开选择客户弹框
    showClient(data) {
      console.log(data);
      this.onCdata = data;
      this.clientFlag = true;
    },
    //   添加商机
    addChance() {
      requestXml(
        "/scrm/Chance/add",
        "POST",
        (res) => {
          if (res == "success") {
            // this.$message.success('分配成功');

            this.toBus(this.onCdata.id);
            // this.corpid = "";
            this.onCdata = "";
            this.clientFlag = false;
            this.$router.push({
              path: "/scrm_pc/client/clientList",
              query: {
                client: this.corpid,
                detail: "clue",
              },
            });
          } else {
            this.$message.info("失败，请重试");
          }
        },
        {
          ids: this.corpid,
          title: this.onCdata.name,
          content: this.onCdata.remarks,
        }
      );
    },
    // getStaff(){
    //     requestXml("/scrm/Staff/getMinList","POST",(res) => {
    //         this.staff=res;
    //     })
    // },
    getData() {
      requestXml(
        "/jieb/clue/showclue",
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].created = getDateTime(res.list[i].created);
            res.list[i].status_text =
              this.statusList[Number(res.list[i].status)];
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
        },
        {
          search: this.key,
          status: this.status,
          staff: this.onStaff.userid,
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      console.log(current);
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    //打开分配弹框
    // openAllocation(id) {
    //   this.onCid = id;
    //   this.visible = true;
    // },
    // 分配线索
    clueAllot() {
      requestXml(
        "/jieb/clue/saveuid",
        "POST",
        (res) => {
          if (res == "success") {
            // this.$message.success('分配成功');
            this.onCid = "";
            this.onUid = "";
            this.visible = false;
            this.getData();
          } else {
            this.$message.info("分配失败，请重试");
          }
        },
        { id: this.onCid, uid: this.onUid }
      );
    },
    // 转商机
    toBus(id) {
      requestXml(
        "//jieb/clue/savestatus_",
        "POST",
        (res) => {
          if (res == "success") {
            // this.$message.success('转为商机成功');
            this.onCid = "";
            this.onUid = "";
            this.visible = false;
            this.getData();
          } else {
            this.$message.info("转为商机失败");
          }
        },
        { id: id }
      );
    },
    //打开放弃弹框
    showGiveUp(id) {
      this.ShowGive = true;
      this.onCid = id;
    },
    // 放弃线索
    submitGiveUp() {
      // console.log(this.giveUpText);
      requestXml(
        "/jieb/clue/savestatus",
        "POST",
        (res) => {
          if (res == "success") {
            // this.$message.success('已放弃');
            this.onCid = "";
            this.giveUpText = "";
            this.ShowGive = false;
            this.getData();
          } else {
            this.$message.info("放弃失败");
          }
        },
        { id: this.onCid, reason: this.giveUpText }
      );
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/scrm_pc/clue/clueEdit",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.add_title {
  padding: 15px 0;
}
.add_inp {
  height: 40px;
  margin-bottom: 60px;
}
.addBox textarea {
  height: 150px;
  margin-bottom: 50px;
}
</style>

